import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BetsStatusesTypes, BETTING_TYPES, CommissionTypes, GAME } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { MatchType } from 'types/bets';
import { TOfferState } from 'types/myBets';
import { getCurrentBetProfit, getCurrentBetSize } from 'utils/betslip';
import { parseMillisecondsToTime } from 'utils/date';
import { betsSideValue, getStatusTranslationKey, oddsValue } from 'utils/myBetsValues';
import { getEventTime } from 'utils/openedBets';

const useOpenBetData = (bet: TCurrentBet) => {
  const { t } = useTranslation();
  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const marketStartTime = parseMillisecondsToTime(bet.marketStartDate || 0, timezone, timezoneCookieEnabled);

  const isGameBet = bet.betType === GAME;
  const isPncBet = bet.commissionType === CommissionTypes.PRICES;
  const isUnmatchedBet = bet.offerState === BetsStatusesTypes.PLACED;
  const isBackBet = bet.side === BetSides.Back;
  const isLayBet = bet.side === BetSides.Lay;
  const isConsolidated = bet.isCombined && bet.combinedAmount! > 1 && bet.matchType === MatchTypes.MATCHED;
  const isEachWay = bet.marketType === MARKET_TYPES.eachWay;

  const betOdds = oddsValue(bet);
  const betSide = t(betsSideValue({ item: bet, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver }));
  const betOfferState = t(getStatusTranslationKey(bet.offerState as TOfferState, isPncBet));

  const formatCurrencySetting = { noRounding: true, isCheckIndian: true, noSymbol: false };

  const offerStateLower = bet.offerState.toLowerCase();
  const isPositiveBet = ['matched', ...(isPncBet ? ['placed'] : [])].includes(offerStateLower);
  const isNegativeBet = ['unmatched', ...(!isPncBet ? ['placed'] : [])].includes(offerStateLower);

  const { noFormattedAmount: betStake } = useFormatCurrency(
    getCurrentBetSize(bet.matchType as MatchType, bet) || bet.sizeCancelled || 0,
    bet.currency,
    formatCurrencySetting
  );

  const { noFormattedAmount: betProfit } = useFormatCurrency(
    Math.abs(Number(getCurrentBetProfit(bet.matchType as MatchType, bet) || 0)),
    bet.currency,
    {
      isCheckIndian: true,
      noRounding: true,
      noSymbol: false,
      isPositiveSign: false,
      noZeroSign: true
    }
  );

  const selectionName = `${bet.selectionName}${
    bet.bettingType === BETTING_TYPES.line ? ` ${bet.averagePriceRounded || bet.price}` : ''
  }`;
  const betMarketName = `${t('games.gameId')}: ${bet.marketId}`;
  const marketName = `${bet.raceName ? `${marketStartTime} ${bet.raceName} - ` : ''}${
    isGameBet ? betMarketName : (!bet.raceName && !bet.isOutright && bet.marketNameWithParents) || bet.marketName
  }`;
  const marketLink = isGameBet
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}`;
  const eventName = bet.mainEventName || bet.eventName;
  const racingEventName = `${eventName} ${bet.marketStartDate && getEventTime(bet.marketStartDate)}`;

  return {
    selectionName,
    marketName,
    marketLink,
    isBackBet,
    isLayBet,
    isGameBet,
    isPncBet,
    isUnmatchedBet,
    isPositiveBet,
    isNegativeBet,
    isConsolidated,
    isEachWay,
    betOdds,
    betSide,
    betStake,
    betProfit,
    betOfferState,
    eventName,
    racingEventName,
    formatCurrencySetting
  };
};

export default useOpenBetData;
