import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import FiltersContainer from 'components/BettingProfitAndLoss/components/FiltersContainer/FiltersContainer';
import TotalPL from 'components/BettingProfitAndLoss/components/TotalPL/TotalPL';
import Button from 'components/Button';
import CancelAllUnmatchedBets from 'components/CancelAllUnmatchedBets/CancelAllUnmatchedBets';
import Icon from 'components/Icon';
import { CookieNames } from 'constants/app';
import { MY_BETS_PAGES, NavigationButtons, PLBetTypes, PLExchangeTypes, SEARCH_CURRENCY_KEY } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useActivePLProduct from 'hooks/useActivePLProduct';
import useCommissionRange from 'hooks/useCommissionRange';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import usePLNetOfCommission from 'hooks/usePLNetOfCommission';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import SearchBar from 'pages/MyBetsPages/components/SearchBar/SearchBar';
import TimeFormatDropdown from 'pages/MyExchangeBetsPage/components/TimeFormatDropdown';
import { getCurrency, getIsExchangeGamesEnabled, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getHasCancelBetsNotification, getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { setSearchValue } from 'redux/modules/myBets';
import {
  bettingPLLoading,
  getIsExportNotification,
  getMyBetsTotalElements,
  getStatementLoading,
  myBetsLoading
} from 'redux/modules/myBets/selectors';
import { TimeFormat } from 'types/myBets';
import { getPageHeadingText } from 'utils/accountPages';

import BetsPeriodTabs from '../BetsPeriodTabs';
import BetTypesDropdown from '../BetTypesDropdown';
import CurrenciesDropdown from '../CurrenciesDropdown';
import DatePickers from '../DatePickers/MyBetsDatePickers';
import DepositBtn from '../DepositBtn/DepositBtn';
import ExportButton from '../ExportButton';
import MyBetsLinks from '../MyBetsLinks/MyBetsLinks';
import ProductsDropdown from '../ProductsDropdown';
import RefreshButton from '../RefreshButton';
import TransactionsDropdown from '../TransactionsDropdown';

import styles from './styles.module.scss';

const MY_BETS_DROPDOWNS: Record<
  string,
  (
    isCurrentPeriod: boolean,
    periodType: string,
    isAsianViewSelected?: boolean,
    isPNCEnabled?: boolean,
    isExchangeGamesEnabled?: boolean
  ) => JSX.Element | null
> = {
  mybets: (isCurrentPeriod, periodType, isAsianViewSelected, isPNCEnabled, isExchangeGamesEnabled) => (
    <>
      {(!isCurrentPeriod || (!isAsianViewSelected && (!isPNCEnabled || isExchangeGamesEnabled))) && (
        <BetTypesDropdown key={periodType} />
      )}
      <CurrenciesDropdown />
    </>
  ),
  profitandloss: () => null,
  statement: () => (
    <>
      <TransactionsDropdown />
      <CurrenciesDropdown />
    </>
  )
};

const MY_BETS_STORE_NAMES: Record<string, 'data' | 'statementData' | 'PLData'> = {
  mybets: 'data',
  profitandloss: 'PLData',
  statement: 'statementData'
};

const AccountPagesHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([CookieNames.MY_BETS_SEARCH]);

  const hasCancelBetsNotification = useSelector(getHasCancelBetsNotification);
  const currency = useSelector(getCurrency);
  const isExportNotification = useSelector(getIsExportNotification);
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);
  const isStatementLoading = useSelector(getStatementLoading);
  const isMyBetsLoading = useSelector(myBetsLoading);
  const isBettingPLLoading = useSelector(bettingPLLoading);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(!!cookies.BIAB_MY_BETS_SEARCH);

  const { refreshData, getExportParams, handleSetEventType, getBetData } = useMyBetsPagesNavigation();
  const { selectedTab, isMyBetsPage, isProfitLossPage, isStatementPage } = useAccountUrlParams();
  const { isCurrentPeriod, periodType } = useMyBetsFilters();
  const { isAsianViewSelected, isProductDropdownEnabled, availableProducts } = useAccountProducts();
  const { commissionRange, showCommissionRange } = useCommissionRange();
  const { hasNetCommission } = usePLNetOfCommission();
  const activePLTab = useActivePLProduct();
  const isPLAsianView = activePLTab === PLExchangeTypes[PLBetTypes.AsianView];

  const isLoadingData =
    (isBettingPLLoading && isProfitLossPage) ||
    (isMyBetsLoading && isMyBetsPage) ||
    (isStatementPage && isStatementLoading);
  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const isAccountStatementPage = selectedTab === NavigationButtons.Statement;

  const totalElements = useSelector(getMyBetsTotalElements(MY_BETS_STORE_NAMES[selectedTab]));

  const pageHeadingText = t(getPageHeadingText(selectedTab));
  const currencyCode = searchCurrency || currency.currencyCode;
  const exportData = getExportParams(currencyCode);

  const isCancelAllEnabled = !isAsianViewSelected && isMyBetsPage && isCurrentPeriod && !isExportNotification;

  useUpdateEffect(() => {
    if (isSearchBarOpen) {
      setIsSearchBarOpen(false);
      dispatch(setSearchValue(null));
      setCookie(CookieNames.MY_BETS_SEARCH, '', { path: '/' });
    }
  }, [periodType, isAsianViewSelected]);

  return (
    <section className={styles.header}>
      <div className={styles.header__row}>
        <div className={classNames(styles.left, styles.left__wideGap)}>
          <h2 className={styles.title}>{pageHeadingText}</h2>
          <div className={styles.filters}>
            {isProductDropdownEnabled && (
              <ProductsDropdown refreshData={refreshData} availableProducts={availableProducts} />
            )}
            {isMyBetsPage && <BetsPeriodTabs />}
          </div>
        </div>
        <div className={styles.right}>
          {isMyBetsPage && <MyBetsLinks />}
          <DepositBtn />
        </div>
      </div>
      <div className={styles.header__divider} />
      <div
        className={classNames(styles.header__row, {
          [styles.header__row__align__top]: isProfitLossPage || isMyBetsPage
        })}
      >
        <div className={styles.left}>
          {!isSearchBarOpen && (
            <>
              {isProfitLossPage && <TotalPL />}
              {isMyBetsPage && (
                <Button
                  variant="secondary"
                  className={styles.searchBtn}
                  onClick={() => setIsSearchBarOpen(true)}
                  disabled={isMyBetsPage && isCancellingBets}
                >
                  <Icon iconClass="fa2-search" fontFamily="fa2" />
                  Search
                </Button>
              )}
              <DatePickers myBetsPage={MY_BETS_PAGES[selectedTab]} />
              {isProfitLossPage && (!isPLAsianView || hasNetCommission) && (
                <FiltersContainer setEventType={handleSetEventType} currencyCode={currencyCode} />
              )}
              {MY_BETS_DROPDOWNS[selectedTab](
                isCurrentPeriod,
                periodType,
                isAsianViewSelected,
                isPNCEnabled,
                isExchangeGamesEnabled
              )}
              {isAccountStatementPage && !isAsianViewSelected && showCommissionRange && (
                <p className={styles.commissionRange}>
                  {t('accountStatement.commissionRange')}:{commissionRange}
                </p>
              )}
            </>
          )}
          {isSearchBarOpen && (
            <SearchBar
              isCurrentPeriod={isCurrentPeriod}
              isOpen={isSearchBarOpen}
              setIsOpen={setIsSearchBarOpen}
              getBetData={getBetData}
            />
          )}
          {isAsianViewSelected && (
            <TimeFormatDropdown onChange={value => refreshData({ timeFormat: value as TimeFormat, asianView: true })} />
          )}
        </div>
        <div className={styles.right}>
          {isCancelAllEnabled && <CancelAllUnmatchedBets />}
          {!hasCancelBetsNotification && (
            <>
              <ExportButton data={exportData} isDisabled={!totalElements || isLoadingData} />
              {!isExportNotification && <RefreshButton onRefresh={refreshData} isDisabled={isLoadingData} />}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AccountPagesHeader;
