import { forwardRef, ReactNode } from 'react';
import { ITooltip, Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';
import { flip, offset, shift } from '@floating-ui/dom';

import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

interface TooltipProps extends ITooltip {
  children?: ReactNode;
  html?: string;
}

export const Tooltip = forwardRef<TooltipRefProps, TooltipProps>(({ children, html, ...props }: TooltipProps, ref) => {
  const { isMobile } = useDevice();

  return (
    <ReactTooltip
      ref={ref}
      id="tooltip"
      place="top"
      positionStrategy="fixed"
      className={styles.tooltip}
      classNameArrow={styles.arrow}
      openOnClick={isMobile}
      delayShow={200}
      delayHide={100}
      middlewares={[flip({ flipAlignment: false }), shift({ padding: 5 }), offset(10)]}
      {...props}
    >
      {html ? <span dangerouslySetInnerHTML={{ __html: html }} /> : children}
    </ReactTooltip>
  );
});

export default Tooltip;
