import { createSelector } from '@reduxjs/toolkit';
import { filter, groupBy, reduce, reverse, sortBy, union } from 'lodash';

import { BETTING_TYPES, CurrentBetActions, EXCHANGE, GAME } from 'constants/app';
import { BetsStatusesTypes } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { getConsolidatedBets } from 'redux/modules/betslip/selectors';
import { getCurrentGameMarket } from 'redux/modules/games/selectors';
import { AppState } from 'redux/reducers';
import { MatchType } from 'types/bets';
import { BetSide } from 'types/myBets';
import { isCancelled } from 'utils/betslip';
import {
  groupByEvent,
  isMatchedOffer,
  isUnmatchedOffer,
  mapBet,
  mapConsolidateBets,
  sortByStartDate
} from 'utils/currentBets';

import { TCurrentBet, TGroupedBetEvent } from './type';

const getCurrentBets = ({ currentBets }: AppState) => currentBets;

export const getCurrentBetsByExchangeType = (isGameType?: boolean) =>
  createSelector(getCurrentBetsByExchangeFiltered(isGameType), offers =>
    filter(offers, {
      betType: isGameType ? GAME : EXCHANGE
    })
  );

const getFilteredCurrentBetsByExchangeType = (
  type: MatchType,
  filterMarketId?: string,
  filterEventId?: string,
  isGameType?: boolean
) =>
  createSelector(getCurrentBetsByExchangeFiltered(isGameType), getCurrentGameMarket, (offers, gameMarket) =>
    filter(offers, {
      betType: isGameType ? GAME : EXCHANGE,
      ...(!isGameType && type === MatchTypes.MATCHED && !!filterMarketId ? { marketId: filterMarketId } : {}),
      ...(!isGameType && type === MatchTypes.MATCHED && !!filterEventId ? { eventId: filterEventId } : {}),
      ...(isGameType && !!filterMarketId && gameMarket && gameMarket.id
        ? { marketId: String(gameMarket.id) ?? '' }
        : {})
    })
  );

export const getCurrentBetsByExchangeFiltered = (isGameType?: boolean) =>
  createSelector(getCurrentBets, currentBets => filter(currentBets.offers, { betType: isGameType ? GAME : EXCHANGE }));

export const getLoading = ({ currentBets }: AppState) => currentBets.loading;

export const getOffers = ({ currentBets }: AppState) => currentBets.offers;

export const getCurrentBetsLoading = ({ currentBets }: AppState) => currentBets.loading && currentBets.isFirstLoad;

export const getCurrentBetsAmount = ({
  isGameType,
  showLapsed,
  ignoreFullyMatchedAction,
  ignoreCancelled,
  isFullyMatchedOnly
}: {
  isGameType?: boolean;
  showLapsed?: boolean;
  ignoreCancelled?: boolean;
  ignoreFullyMatchedAction?: boolean;
  isFullyMatchedOnly?: boolean;
}) =>
  createSelector(
    getCurrentBetsByType({
      type: MatchTypes.UNMATCHED,
      isGameType,
      ignoreFullyMatchedAction,
      ignoreCancelled,
      showLapsed
    }),
    getCurrentBetsByType({ type: MatchTypes.MATCHED, isGameType, ignoreCancelled, isFullyMatchedOnly }),
    (unmatchedOffers, matchedOffers) => {
      const offers: TCurrentBet[] = union(unmatchedOffers, matchedOffers);
      return offers.map(offer => offer.offerId).length;
    }
  );
// Get current bet by offerId
export const getCurrentBetByOfferId =
  (offerId?: number) =>
  ({ currentBets }: AppState) =>
    offerId ? currentBets.offers[offerId] : null;

// Get current bets by old offerId
export const getCurrentBetsByOldOfferId = (oldOfferId: number) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(bet.oldOfferId === oldOfferId ? [mapBet(bet)] : [])];
      },
      []
    )
  );

// Get MATCHED or UNMATCHED offers list by event id
export const getCurrentEventBetsByType = (eventId: string, type: MatchType) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(bet.eventId === eventId &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED &&
              isUnmatchedOffer({ bet }) &&
              bet.offerState !== BetsStatusesTypes.CANCELLED))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

export const getCurrentBetsAndGames = createSelector(getOffers, offers => Object.values(offers));

export const getIsCurrentEventBetsByType = (eventId: string, type: MatchType) =>
  createSelector(getCurrentEventBetsByType(eventId, type), bets => bets.length > 0);

export const getCurrentBetsError = ({ currentBets }: AppState) => currentBets.error;

export const getCurrentBetsString = ({ currentBets }: AppState) => currentBets.currentBetsString;
export const getCurrentBetsList = ({ currentBets }: AppState) => currentBets.currentBetsList;

export const getCurrentBetsGroupedByEvent = ({
  isGameType,
  filterMarketId,
  filterEventId
}: {
  isGameType?: boolean;
  filterMarketId?: string;
  filterEventId?: string;
}) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), getConsolidatedBets, (betsList, areBetsConsolidated) => {
    let bets: TCurrentBet[] = betsList;

    if (filterEventId) {
      bets = bets.filter(bet => bet.eventId === filterEventId);
    } else if (filterMarketId) {
      bets = bets.filter(bet => bet.marketId === filterMarketId);
    }

    bets = bets.reduce<TCurrentBet[]>((list, bet) => {
      if (isMatchedOffer(bet) && bet.offerState !== BetsStatusesTypes.CANCELLED) {
        list.push({ ...mapBet(bet), matchType: MatchTypes.MATCHED });
      }

      return list;
    }, []);

    bets = areBetsConsolidated && !isGameType ? mapConsolidateBets(bets) : bets;

    bets = sortByStartDate(bets);

    const betsGroupedByEvent = groupByEvent(bets);

    return betsGroupedByEvent.map<TGroupedBetEvent>(groupedData => {
      return {
        eventId: groupedData.eventData.eventId,
        mainEventId: groupedData.eventData.mainEventId,
        competitionId: groupedData.eventData.competitionId,
        marketStartDate: groupedData.eventData.marketStartDate,
        isPNLAvailable: groupedData.bets.some(bet => bet.isPNLAvailable),
        betsByType: groupBy(groupedData.bets, 'side')
      } as TGroupedBetEvent;
    });
  });

// Get MATCHED or UNMATCHED offers list by selection
export const getCurrentBetsBySelection = ({
  type,
  selectionId,
  handicap,
  marketId,
  showLapsedAlways,
  ignoreCancelled = false,
  showCancelledIfEditing
}: {
  type: MatchType;
  marketId: string;
  selectionId?: number;
  handicap?: number;
  showLapsedAlways?: boolean;
  ignoreCancelled?: boolean;
  showCancelledIfEditing?: boolean;
}) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet) => {
        const isValidSelection = !selectionId || (!!selectionId && bet.selectionId === selectionId);
        const isValidHandicap =
          !handicap || (handicap && bet.handicap == handicap) || bet.bettingType === BETTING_TYPES.line;
        const isNotHidden = bet.action !== CurrentBetActions.HIDDEN;
        const isMatched = type === MatchTypes.MATCHED && isMatchedOffer(bet);
        const isUnmatched =
          type === MatchTypes.UNMATCHED &&
          isUnmatchedOffer({ bet, showLapsedAlways }) &&
          bet.action !== CurrentBetActions.FULLY_MATCHED;
        const showBetIfCancelled =
          !ignoreCancelled ||
          (showCancelledIfEditing && bet.action === CurrentBetActions.EDITING) ||
          bet.offerState !== BetsStatusesTypes.CANCELLED;
        const isCancelledBet =
          (bet.action === CurrentBetActions.CANCELLING || bet.action === CurrentBetActions.CANCELLING_ALL) &&
          isCancelled(bet);

        if (
          showBetIfCancelled &&
          bet.marketId === marketId &&
          isValidSelection &&
          isValidHandicap &&
          isNotHidden &&
          !isCancelledBet &&
          (isMatched || isUnmatched)
        ) {
          return [...res, mapBet(bet)];
        }

        return res;
      },
      []
    )
  );

export const getIsCurrentBetsBySelection = ({
  type,
  marketId,
  selectionId,
  handicap,
  showLapsedAlways
}: {
  type: MatchType;
  marketId: string;
  selectionId?: number;
  handicap?: number;
  showLapsedAlways?: boolean;
}) =>
  createSelector(
    getCurrentBetsBySelection({ type, marketId, selectionId, handicap, showLapsedAlways }),
    bets => bets.length > 0
  );

export const getUnmatchedOffersIdsToShowLapsed = ({ currentBets }: AppState) =>
  currentBets.unmatchedOffersIdsToShowLapsed;
export const getClosedUnmatchedBetOfferIds = ({ currentBets }: AppState) => currentBets.closedUnmatchedOfferIds;

// Get MATCHED or UNMATCHED offers list
export const getCurrentBetsByType = ({
  type,
  isGameType = false,
  ignoreCancelled = false,
  anyCancelled = false,
  showLapsed = false,
  ignoreFullyMatchedAction = false,
  isFullyMatchedOnly = false
}: {
  type: MatchType;
  isGameType?: boolean;
  ignoreCancelled?: boolean;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  ignoreFullyMatchedAction?: boolean;
  isFullyMatchedOnly?: boolean;
}) =>
  createSelector(
    getCurrentBetsByExchangeType(isGameType),
    getUnmatchedOffersIdsToShowLapsed,
    getClosedUnmatchedBetOfferIds,
    (offers, unmatchedOffersIdsToShowLapsed, closedUnmatchedOfferIds) => {
      return reduce(
        offers,
        (res: TCurrentBet[], bet: TCurrentBet) => {
          const showBetIfCancelled = !ignoreCancelled || bet.offerState !== BetsStatusesTypes.CANCELLED;
          const showBetIfFullyMatchedAction =
            !ignoreFullyMatchedAction || bet.action !== CurrentBetActions.FULLY_MATCHED;
          const isMatchedBet = type === MatchTypes.MATCHED && isMatchedOffer(bet, isFullyMatchedOnly);
          const isUnmatchedBet =
            type === MatchTypes.UNMATCHED &&
            isUnmatchedOffer({ bet, anyCancelled, showLapsed, unmatchedOffersIdsToShowLapsed });
          const isNotOldCancelled =
            bet.offerState !== BetsStatusesTypes.CANCELLED ||
            offers.find(offer => offer.oldOfferId === bet.offerId)?.offerState !== BetsStatusesTypes.CANCELLED;

          if (
            showBetIfCancelled &&
            showBetIfFullyMatchedAction &&
            (isMatchedBet || isUnmatchedBet) &&
            isNotOldCancelled &&
            (type === MatchTypes.MATCHED || !closedUnmatchedOfferIds[bet.offerId])
          ) {
            return [...res, mapBet(bet)];
          }

          return res;
        },
        []
      );
    }
  );

export const getCurrentUnmatchedBets = ({
  ignoreCancelled = false,
  anyCancelled = false,
  showLapsed = false,
  ignoreFullyMatchedAction = false
}: {
  ignoreCancelled?: boolean;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  ignoreFullyMatchedAction?: boolean;
}) =>
  createSelector(
    getOffers,
    getUnmatchedOffersIdsToShowLapsed,
    getClosedUnmatchedBetOfferIds,
    (offers, unmatchedOffersIdsToShowLapsed, closedUnmatchedOfferIds) => {
      return reduce(
        offers,
        (res: TCurrentBet[], bet: TCurrentBet) => {
          const showBetIfCancelled = !ignoreCancelled || bet.offerState !== BetsStatusesTypes.CANCELLED;
          const showBetIfFullyMatchedAction =
            !ignoreFullyMatchedAction || bet.action !== CurrentBetActions.FULLY_MATCHED;
          const isUnmatchedBet = isUnmatchedOffer({ bet, anyCancelled, showLapsed, unmatchedOffersIdsToShowLapsed });

          if (
            showBetIfCancelled &&
            showBetIfFullyMatchedAction &&
            isUnmatchedBet &&
            !closedUnmatchedOfferIds[bet.offerId]
          ) {
            return [...res, mapBet(bet)];
          }

          return res;
        },
        []
      );
    }
  );

export const getCurrentBetsLengthByType = (
  params:
    | {
        type: MatchType;
        isGameType?: boolean;
        ignoreCancelled?: boolean;
        anyCancelled?: boolean;
        showLapsed?: boolean;
        ignoreFullyMatchedAction?: boolean;
      }
    | undefined
) => {
  if (params === undefined) {
    return () => 0;
  }

  const {
    type,
    isGameType = false,
    ignoreCancelled = false,
    anyCancelled = false,
    showLapsed = false,
    ignoreFullyMatchedAction
  } = params;

  return createSelector(
    getCurrentBetsByType({ type, isGameType, ignoreCancelled, anyCancelled, showLapsed, ignoreFullyMatchedAction }),
    bets => bets.length
  );
};

export const getCurrentBetsByOfferIds = (offerIds: number[]) =>
  createSelector(getOffers, offers => Object.values(offers).filter(offer => offerIds.includes(offer.offerId)));
export const getUpdatedOffers = ({ currentBets }: AppState) => currentBets.updatedOffers;
export const getNewOffersByOldOfferIds = (offerIds: number[]) =>
  createSelector(getOffers, offers => Object.values(offers).filter(offer => offerIds.includes(offer.oldOfferId)));

export const getIsAllUnmatchedBetsCancellingOrEditing = (isGameType: boolean) =>
  createSelector(getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType }), unmatchedBets => {
    return unmatchedBets.every(
      ({ action }) => action === CurrentBetActions.CANCELLING || action === CurrentBetActions.EDITING
    );
  });

// Get offers by BACK/LAY side and MATCHED/UNMATCHED type
export const getCurrentBetsBySideType = (
  side: BetSide,
  type: MatchType,
  filterMarketId?: string,
  filterEventId?: string,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(getFilteredCurrentBetsByExchangeType(type, filterMarketId, filterEventId, isGameType), offers => {
    const openedBets = reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(bet.side === side ? [mapBet(bet)] : [])];
      },
      []
    ).filter(bet => {
      return type === MatchTypes.MATCHED
        ? isMatchedOffer(bet) && bet.offerState !== BetsStatusesTypes.CANCELLED
        : isUnmatchedOffer({ bet });
    });

    if (type === MatchTypes.MATCHED && isConsolidateBets && !isGameType) {
      return mapConsolidateBets(openedBets);
    }

    return openedBets;
  });

export const getCurrentBetsLengthBySideType = (
  side: BetSide,
  type: MatchType,
  filterMarketId?: string,
  filterEventId?: string,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(
    getCurrentBetsBySideType(side, type, filterMarketId, filterEventId, isConsolidateBets, isGameType),
    bets => bets.length
  );

// Get offers sorted by placedDate
export const getSortedCurrentBets = (isGameType?: boolean) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers =>
    reduce(
      reverse(sortBy(offers, 'placedDate')),
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(isUnmatchedOffer({ bet }) ? [{ ...mapBet(bet), matchType: MatchTypes.UNMATCHED }] : []),
          // if bet is partially matched show both parts
          ...(isMatchedOffer(bet) ? [{ ...mapBet(bet), matchType: MatchTypes.MATCHED }] : [])
        ];
      },
      []
    )
  );

export const getSortedMatchedCurrentBets = ({
  consolidateBets,
  isGameType,
  filterEventId,
  filterMarketId
}: {
  consolidateBets: boolean;
  isGameType?: boolean;
  filterMarketId?: string;
  filterEventId?: string;
}) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers => {
    let filteredOffers = offers;

    if (filterMarketId) {
      filteredOffers = filter(offers, { marketId: filterMarketId });
    } else if (filterEventId) {
      filteredOffers = filter(offers, { eventId: filterEventId });
    }

    const sortedBets = reduce(
      reverse(sortBy(filteredOffers, 'placedDate')),
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(isMatchedOffer(bet) && bet.offerState !== BetsStatusesTypes.CANCELLED
            ? [{ ...mapBet(bet), matchType: MatchTypes.MATCHED }]
            : [])
        ];
      },
      []
    );

    if (consolidateBets && !isGameType) {
      return mapConsolidateBets(sortedBets);
    }

    return sortedBets;
  });

// Get offers count
export const getCurrentBetsCount = createSelector(getOffers, offers => Object.keys(offers).length);

export const isMarketHasOffers = (marketId?: string) =>
  createSelector(getOffers, offers =>
    marketId ? !!Object.values(offers).find(offer => offer.marketId === marketId) : null
  );

export const getCurrentSelectionBetsByTypeForWhatIf = (marketId: string, handicap: number, type: MatchType) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        if (
          bet.marketId === marketId &&
          bet.handicap == handicap &&
          type === MatchTypes.UNMATCHED &&
          isUnmatchedOffer({ bet }) &&
          bet.offerState !== BetsStatusesTypes.CANCELLED &&
          (bet.changedSize || bet.changedPrice)
        ) {
          res.push(bet);
        }

        return res;
      },
      []
    )
  );

export const getPlacedCashOut = (offerId: number | null) => (state: AppState) =>
  offerId ? state.currentBets.offers[offerId] : null;

export const getCurrentBetsBySideTypeLengthByMarketId = (
  marketId: string,
  side: BetSide,
  type: MatchType,
  filterMarketId?: string,
  filterEventId?: string,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(
    getCurrentBetsBySideType(side, type, filterMarketId, filterEventId, isConsolidateBets, isGameType),
    bets => bets.filter(bet => bet.marketId === marketId).length
  );

export const getAllMatchedCurrentBetsByEventId = (eventId: string | null, isGameType?: boolean) =>
  createSelector(getCurrentBetsByType({ type: MatchTypes.MATCHED, isGameType }), bets => {
    return eventId ? bets.filter(bet => bet.eventId === eventId).length : 0;
  });

export const getAreCurrentBetsLoaded = ({ currentBets }: AppState) => currentBets.areCurrentBetsLoaded;

export const getIsAllCurrentBetsCancelling = (curMarketId?: string, isGameType?: boolean) =>
  createSelector(getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType, ignoreCancelled: true }), offers =>
    offers.some(
      ({ marketId, action }) =>
        (!curMarketId || marketId === curMarketId) && action === CurrentBetActions.CANCELLING_ALL
    )
  );

export const getIsAtLeastOneCanBeRemovedCurrentBet = createSelector(getOffers, offers =>
  Object.values(offers).some(bet => bet.canBeRemoved)
);

export const getAllCurrentBetsOfferIds = createSelector(getCurrentBetsList, bets => {
  return bets.map(({ offerId }) => offerId);
});

export const getCurrentEventBetsAmountByType = ({
  eventId,
  type,
  isGameType,
  ignoreCancelled,
  anyCancelled,
  showLapsed,
  ignoreFullyMatchedAction
}: {
  eventId: string;
  type: MatchType;
  isGameType?: boolean;
  ignoreCancelled?: boolean;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  ignoreFullyMatchedAction?: boolean;
}) =>
  createSelector(
    getCurrentBetsByType({ type, isGameType, ignoreCancelled, anyCancelled, showLapsed, ignoreFullyMatchedAction }),
    bets => bets.filter(bet => bet.eventId === eventId).length
  );
export const getCurrentSelectionBetsAmountByType = ({
  marketId,
  selectionId,
  handicap,
  type,
  isGameType,
  ignoreCancelled,
  anyCancelled,
  showLapsed,
  ignoreFullyMatchedAction
}: {
  marketId: string;
  selectionId: number;
  handicap: number;
  type: MatchType;
  isGameType?: boolean;
  ignoreCancelled?: boolean;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  ignoreFullyMatchedAction?: boolean;
}) =>
  createSelector(
    getCurrentBetsByType({ type, isGameType, ignoreCancelled, anyCancelled, showLapsed, ignoreFullyMatchedAction }),
    bets => {
      return bets.filter(
        bet => bet.marketId === marketId && bet.selectionId === selectionId && bet.handicap == handicap
      ).length;
    }
  );

export const getCurrentBetOfferStateByOfferId =
  (offerId?: number) =>
  ({ currentBets }: AppState) =>
    offerId ? currentBets.offers[offerId]?.offerState : undefined;
