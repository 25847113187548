import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { CookieNames } from 'constants/app';
import { mobileAccountPagesBranding } from 'constants/branding';
import {
  BetsTypesByPeriods,
  BetTypeValues,
  ExchangeTypes,
  MyBetsPeriods,
  PLBetTypes,
  PLExchangeTypes,
  StatementTransactionTypes
} from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { getTimezone } from 'redux/modules/appConfigs/selectors';
import {
  resetAccountStatementTransactionTypes,
  setAccountStatementTransactionsTypes,
  setEndDate,
  setEventTypeFilter,
  setIsMobileFiltersOpen,
  setMobileBettingPLSorting,
  setMobileSorting,
  setMobileStatementSorting,
  setPageNumber,
  setStartDate
} from 'redux/modules/myBets';
import { getIsMobileFiltersChanged, getMobileFiltersChanged } from 'redux/modules/myBets/selectors';
import { TSortByType } from 'redux/modules/myBets/type';
import { TimeFormats } from 'types';
import { BetType } from 'types/myBets';
import { getDatesRange } from 'utils/date';

import styles from './styles.module.scss';

const allTransactionTypes = [
  StatementTransactionTypes.BETTING,
  StatementTransactionTypes.DEPOSIT_WITHDRAWAL,
  StatementTransactionTypes.COMMISSION,
  StatementTransactionTypes.RESETTLEMENT_CORRECTION
];

const MobileAccountFiltersHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [, setCookie] = useCookies([
    CookieNames.EXCHANGE_TYPE_NEW,
    CookieNames.PROFITANDLOSS_BETTYPE,
    CookieNames.PL_NET_COMMISSION,
    CookieNames.PROFITANDLOSS_SPORTID,
    CookieNames.TIME_FORMAT,
    CookieNames.ACCOUNT_DATEFROM,
    CookieNames.ACCOUNT_DATETO
  ]);

  const { selectedProduct } = useAccountProducts();
  const { refreshData } = useMyBetsPagesNavigation();
  const { isProfitLossPage, isMyBetsPage } = useAccountUrlParams();
  const timezone = useSelector(getTimezone);
  const isChanged = useSelector(getIsMobileFiltersChanged);
  const {
    betTypes,
    startDate,
    endDate,
    currency,
    product,
    isStatementSortingChanged,
    statementSorting,
    isTransactionsTypesChanged,
    transactionsTypes,
    netCommission,
    isNetCommissionChanged,
    eventTypeId,
    isEventTypeIdChanged,
    sorting,
    isSortingChanged,
    timeDisplayFormat,
    isTimeDisplayFormatChanged
  } = useSelector(getMobileFiltersChanged);

  const { setBetsTypes, periodType } = useMyBetsFilters();

  const closeFiltersHandler = () => {
    dispatch(setIsMobileFiltersOpen(false));
  };

  const updateFiltersHandler = () => {
    let updatedBetTypes: BetType[] = [];

    if (isMyBetsPage) {
      if (!betTypes?.length) {
        updatedBetTypes =
          periodType === MyBetsPeriods.Settled
            ? BetsTypesByPeriods[MyBetsPeriods.Settled]
            : BetsTypesByPeriods[MyBetsPeriods.Open];
      } else {
        updatedBetTypes = betTypes;
      }

      setBetsTypes(updatedBetTypes);
    }

    dispatch(setIsMobileFiltersOpen(false));

    if (startDate && endDate) {
      const [startDateRange, endDateRange] = getDatesRange(startDate, endDate, timezone);

      dispatch(setPageNumber(0));
      dispatch(setStartDate(startDateRange));
      dispatch(setEndDate(endDateRange));

      setCookie(CookieNames.ACCOUNT_DATEFROM, new Date(startDateRange), {
        path: '/'
      });
      setCookie(CookieNames.ACCOUNT_DATETO, new Date(endDateRange), {
        path: '/'
      });
    }

    if (isStatementSortingChanged && statementSorting) {
      dispatch(setMobileStatementSorting({ name: statementSorting, order: 'desc' }));
    }

    if (isTransactionsTypesChanged && transactionsTypes) {
      dispatch(setAccountStatementTransactionsTypes(transactionsTypes));
    }

    if (isProfitLossPage && isNetCommissionChanged) {
      setCookie(CookieNames.PL_NET_COMMISSION, netCommission, { path: '/' });
    }

    if (isSortingChanged && sorting) {
      if (isMyBetsPage) {
        dispatch(setMobileSorting({ type: sorting as TSortByType, order: 'desc' }));
      } else if (isProfitLossPage) {
        dispatch(setMobileBettingPLSorting({ type: sorting as TSortByType, order: 'desc' }));
      }
    }

    if (transactionsTypes?.length === 0) {
      dispatch(resetAccountStatementTransactionTypes());
    }

    if (isTimeDisplayFormatChanged && timeDisplayFormat) {
      setCookie(CookieNames.TIME_FORMAT, timeDisplayFormat, { path: '/' });
    }

    if (isProfitLossPage && isEventTypeIdChanged) {
      if (eventTypeId || eventTypeId === '') {
        dispatch(setEventTypeFilter({ id: eventTypeId.toString(), label: '', value: '' }));
      }
      setCookie(CookieNames.PROFITANDLOSS_SPORTID, eventTypeId, {
        path: '/'
      });
    }

    if (product) {
      setCookie(isProfitLossPage ? CookieNames.PROFITANDLOSS_BETTYPE : CookieNames.EXCHANGE_TYPE_NEW, product, {
        path: '/'
      });
    }

    refreshData({
      ...(startDate ? { refreshedStartDate: startDate } : {}),
      ...(endDate ? { refreshedEndDate: endDate } : {}),
      ...(isMyBetsPage && updatedBetTypes?.length ? { betsTypes: updatedBetTypes } : {}),
      ...(currency ? { currency } : {}),
      ...(netCommission ? { netCommission } : {}),
      ...(isProfitLossPage
        ? { betType: product === PLExchangeTypes[PLBetTypes.Games] ? BetTypeValues.Games : BetTypeValues.Sports }
        : {}),
      eventTypeId: eventTypeId?.toString() || 'all',
      ...(transactionsTypes
        ? { statementTransactions: transactionsTypes?.length === 0 ? allTransactionTypes : transactionsTypes }
        : {}),
      ...(statementSorting ? { sortBy: { [statementSorting]: 'desc' } } : {}),
      ...(sorting ? { sortBy: { [sorting]: 'desc' } } : {}),
      ...(timeDisplayFormat
        ? {
            timeFormat: timeDisplayFormat === TimeFormats.BETTING_DAY ? TimeFormats.BETTING_DAY : TimeFormats.LOCAL_TIME
          }
        : {}),
      ...(isProfitLossPage && product
        ? { betType: product === PLExchangeTypes[PLBetTypes.Games] ? BetTypeValues.Games : BetTypeValues.Sports }
        : {}),
      asianView: product ? product === ExchangeTypes.AsianView : selectedProduct === ExchangeTypes.AsianView,
      number: 0
    });
  };

  return (
    <section className={classNames(styles.header, mobileAccountPagesBranding.FILTERS)}>
      <button type="button" className={styles.btn} onClick={closeFiltersHandler}>
        <i className="biab_custom-icon-chevron-left-24" />
      </button>
      <h2 className={styles.title}>{t('account.labels.filters')}</h2>
      <button
        type="button"
        className={classNames(styles.btn, {
          [styles.btn__disabled]: !isChanged,
          [styles.btn__changed]: isChanged,
          [mobileAccountPagesBranding.APPLY_BUTTON]: isChanged
        })}
        disabled={!isChanged}
        onClick={updateFiltersHandler}
      >
        <i className="biab_custom-icon-check-24" />
      </button>
    </section>
  );
};

export default MobileAccountFiltersHeader;
