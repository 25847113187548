import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import { CurrentBetActions, PageBlocks } from 'constants/app';
import { OPEN_BET_BTN_CANCEL, OPEN_BET_BTN_TAKE, OPEN_BET_BTN_UPDATE } from 'constants/automation';
import {
  BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT,
  BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT,
  KEY_CODES,
  tabulation
} from 'constants/betslip';
import { BetActions as BetActionsMap } from 'constants/betslip';
import { componentsBranding } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { BETSLIP_CANCEL_BET, BETSLIP_IM_UPDATE_BET, BETSLIP_UPDATE_BET } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { usePlacementData } from 'hooks/usePlacement';
import useTooltip from 'hooks/useTooltip';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import {
  removePlacedBetsToUpdate,
  setBetSlipUnmatchedOfferError,
  setFirstOpenedBetFocused,
  setRGErrorMessage,
  setUnmatchedBetProgress
} from 'redux/modules/betslip';
import { getIsGameBetSlip, getPlacedBetsToUpdate } from 'redux/modules/betslip/selectors';
import { setCurrentBetAction, setCurrentBetCanBeRemoved } from 'redux/modules/currentBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getMarketPricesById } from 'redux/modules/marketsPrices/selectors';
import { getMarketPricesById as placementById } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { PersistenceType, TPrice, TSize } from 'types/bets';
import { BetAction, BetMessageType } from 'types/betslip';
import { getIsFullyMatched, getIsPartiallyMatched, isResponsibleGamblingError } from 'utils/betslip';
import { calculateLiability } from 'utils/liability';
import { getPricesByMarketType } from 'utils/price';

import styles from './styles.module.scss';

type BetActionsProps = {
  bet: TCurrentBet;
  updatedPrice: TPrice;
  updatedSize: TSize;
  updatedPersistenceType: PersistenceType;
  currency?: string;
  isSizeValid: boolean;
  isUpdateEnabled: boolean;
  betIndex?: number;
  isLast?: boolean;
  isDisabled: boolean;
  setUnmatchedBetMessageType: Dispatch<SetStateAction<BetMessageType>>;
  setIsLongPlacingMessage: Dispatch<SetStateAction<boolean>>;
  isLongPlacingMessage: boolean;
};

const BetActions = ({
  bet,
  updatedPrice,
  updatedSize,
  updatedPersistenceType,
  currency,
  isSizeValid,
  isUpdateEnabled,
  betIndex = 0,
  isLast,
  isDisabled,
  setUnmatchedBetMessageType,
  setIsLongPlacingMessage,
  isLongPlacingMessage
}: BetActionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const marketPrices = useSelector(getMarketPricesById(bet.marketId));
  const gameMarketPrices = useSelector(placementById(PageBlocks.GAME, bet.marketId));
  const betsToUpdate = useSelector(getPlacedBetsToUpdate);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const [betAction, setBetAction] = useState<BetAction>(BetActionsMap.NONE);

  const placingBetTimeout = useRef<NodeJS.Timeout | null>(null);
  const failedPlacingBetTimeout = useRef<NodeJS.Timeout | null>(null);

  const isLineMarket = bet.bettingType === BettingTypes.LINE;

  const { translationKey, isEnabled } = useTooltip(BETSLIP_CANCEL_BET);
  const { translationKey: translationKeyBetslipUpdate, isEnabled: isEnabledBetslipUpdate } = useTooltip(
    isLineMarket ? BETSLIP_IM_UPDATE_BET : BETSLIP_UPDATE_BET
  );
  const marketUnitTranslated = useMarketUnits(bet.marketUnit ?? '');

  const onErrorPlacement = (error: TPlacementError | string = '') => {
    setBetAction(BetActionsMap.NONE);
    dispatch(setBetSlipUnmatchedOfferError({ offerId: bet.offerId, error }));
    dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));

    if (isLongPlacingMessage) {
      setIsLongPlacingMessage(false);
    }

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }
  };

  const { cancelBetsHandler, editBetsHandler } = usePlacementData({
    eachWayDivisor: bet.eachWayDivisor,
    numberOfWinners: bet.numberOfWinners,
    successPlacement: () => {
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    },
    errorPlacement: onErrorPlacement
  });

  const selectionPrices = isGameBetSlip
    ? gameMarketPrices?.rc?.find(rc => rc.id === bet.selectionId)
    : marketPrices?.rc?.find(rc =>
        isLineMarket
          ? rc.id === bet.selectionId
          : rc.id === bet.selectionId && ((rc.hc || 0) === +(bet.handicap || 0) || bet.handicap === 0)
      );

  const takeOdds = getPricesByMarketType(
    selectionPrices?.[bet.side === BetSides.Back ? 'bdatb' : 'bdatl']?.[0]?.odds,
    bet.marketType,
    bet.bettingType
  );

  const { marketType, bettingType, eachWayDivisor, side: betType } = bet;
  const takeProfit = calculateLiability(takeOdds, updatedSize, { marketType, bettingType, eachWayDivisor, betType });
  const { noFormattedAmount: profit } = useFormatCurrency(takeProfit || 0, currency, {
    isCheckIndian: true,
    noRounding: true
  });

  const takeOddsLabel = isLineMarket
    ? t('betslip.labels.btn.take.units', { units: `${takeOdds || 0} ${marketUnitTranslated}` })
    : t('betslip.labels.btn.take', { odds: takeOdds || 0 });

  const getFieldOrder = (order = 0) => betIndex * tabulation.OPENED_BET_TABS + order;

  useEffect(() => {
    return () => {
      if (placingBetTimeout.current) {
        clearTimeout(placingBetTimeout.current);
      }

      if (failedPlacingBetTimeout.current) {
        clearTimeout(failedPlacingBetTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (betsToUpdate[bet.offerId]) {
      dispatch(removePlacedBetsToUpdate(bet.offerId));

      if (isUpdateEnabled) {
        updateBetHandler(updatedPrice);
      }
    }
  }, [betsToUpdate]);

  useEffect(() => {
    if (betAction === BetActionsMap.PROGRESS) {
      placingBetTimeout.current = setTimeout(() => {
        setUnmatchedBetMessageType('info');
        setIsLongPlacingMessage(true);
      }, BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT);

      failedPlacingBetTimeout.current = setTimeout(() => {
        setBetAction(BetActionsMap.NONE);
        setIsLongPlacingMessage(false);
        setUnmatchedBetMessageType(null);
        dispatch(setBetSlipUnmatchedOfferError({ offerId: bet.offerId, error: t('betslip.labels.notPlaced') }));
        dispatch(setCurrentBetCanBeRemoved({ offerId: bet.offerId, canBeRemoved: true }));
        dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
      }, BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT);
    } else {
      if (placingBetTimeout.current) {
        clearTimeout(placingBetTimeout.current);
        placingBetTimeout.current = null;
      }

      if (failedPlacingBetTimeout.current) {
        clearTimeout(failedPlacingBetTimeout.current);
        placingBetTimeout.current = null;
      }
    }
  }, [betAction]);

  useEffect(() => {
    const isFullyMatched = getIsFullyMatched(bet);
    const isPartiallyMatched = getIsPartiallyMatched(bet);
    const isCancelled = Number(bet.sizeCancelled) > 0 && Number(bet.sizeRemaining) === 0;

    if (
      isFullyMatched ||
      isPartiallyMatched ||
      bet.action === CurrentBetActions.FULLY_MATCHED_AFTER_EDITING ||
      isCancelled ||
      bet.action === CurrentBetActions.PARTIALLY_MATCHED
    ) {
      setBetAction(BetActionsMap.NONE);

      if (placingBetTimeout.current) {
        clearTimeout(placingBetTimeout.current);
        placingBetTimeout.current = null;
      }

      if (failedPlacingBetTimeout.current) {
        clearTimeout(failedPlacingBetTimeout.current);
        placingBetTimeout.current = null;
      }
    }
  }, [bet.sizeMatched, bet.sizeRemaining]);

  const cancelBetHandler = () => {
    if (!isDisabled) {
      setBetAction(BetActionsMap.PROGRESS);

      cancelBetsHandler({
        marketId: bet.marketId,
        bets: [{ ...bet }],
        onSuccess: () => setBetAction(BetActionsMap.NONE)
      });

      dispatch(setUnmatchedBetProgress({ offerId: bet.offerId, progress: 0 }));
    }
  };

  const updateBetHandler = (priceValue: TPrice) => {
    if (!isDisabled) {
      setBetAction(BetActionsMap.PROGRESS);

      editBetsHandler({
        marketId: bet.marketId,
        bets: [
          {
            price: priceValue,
            size: updatedSize,
            side: bet.side,
            selectionId: bet.selectionId,
            handicap: bet.handicap,
            offerId: bet.offerId,
            sizeRemaining: bet.sizeRemaining,
            persistenceType: updatedPersistenceType
          }
        ],
        options: {
          betType: bet.betType
        }
      });

      dispatch(setUnmatchedBetProgress({ offerId: bet.offerId, progress: 0 }));
    }
  };

  const keyDownHandler =
    (isRestartTabNavigation = false) =>
    (event: KeyboardEvent) => {
      if (event.key === KEY_CODES.TAB && isLast && isRestartTabNavigation && !isDisabled) {
        event.preventDefault();
        dispatch(setFirstOpenedBetFocused(true));
      }
    };

  if (bet.action === CurrentBetActions.CANCELLING || bet.action === CurrentBetActions.EDITING) {
    return null;
  }

  return (
    <div className={styles.openBetButtonsWrap}>
      <button
        data-tooltip-id="tooltip"
        data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
        className={classNames(styles.openBetButton, styles.openBetButton__cancel, componentsBranding.SECONDARY_BTN, {
          [componentsBranding.DISABLED]: isDisabled,
          [styles.openBetButton__cancel__disabled]: isDisabled
        })}
        type="button"
        tabIndex={getFieldOrder(tabulation.CANCEL_OPENED_BET_ORDER)}
        onClick={cancelBetHandler}
        onKeyDown={keyDownHandler((!takeOdds || !isSizeValid) && !isUpdateEnabled)}
        disabled={isDisabled}
        data-auto={OPEN_BET_BTN_CANCEL}
      >
        {t('betslip.labels.btn.cancelBet')}
      </button>
      <button
        className={classNames(styles.openBetButton, styles.openBetButton__take, componentsBranding.PRIMARY_BTN, {
          [componentsBranding.DISABLED]: isDisabled || !takeOdds || !isSizeValid || takeOdds === bet.price,
          [styles.openBetButton__disabled]: isDisabled || !takeOdds || !isSizeValid || takeOdds === bet.price
        })}
        type="button"
        tabIndex={getFieldOrder(tabulation.TAKE_OPENED_BET_ORDER)}
        disabled={isDisabled || !takeOdds || !isSizeValid || takeOdds === bet.price}
        onClick={() => updateBetHandler(takeOdds)}
        onKeyDown={keyDownHandler(!isUpdateEnabled)}
        data-auto={OPEN_BET_BTN_TAKE}
      >
        <span>{takeOddsLabel}</span>
        <span className={styles.openBetButton__profit}>
          {t('betslip.labels.take.profit.' + bet.side.toLowerCase())}: {profit}
        </span>
      </button>
      <button
        data-tooltip-id="tooltip"
        data-tooltip-html={isEnabledBetslipUpdate ? unescape(t(translationKeyBetslipUpdate)) : ''}
        className={classNames(styles.openBetButton, styles.openBetButton__update, componentsBranding.PRIMARY_BTN, {
          [componentsBranding.DISABLED]: isDisabled || !isUpdateEnabled,
          [styles.openBetButton__disabled]: isDisabled || !isUpdateEnabled,
          [styles.openBetButton__update__disabled]: isDisabled || !isUpdateEnabled
        })}
        type="button"
        tabIndex={getFieldOrder(tabulation.UPDATE_OPENED_BET_ORDER)}
        disabled={isDisabled || !isUpdateEnabled}
        onClick={() => updateBetHandler(updatedPrice)}
        onKeyDown={keyDownHandler(isUpdateEnabled)}
        data-auto={OPEN_BET_BTN_UPDATE}
      >
        {t('betslip.labels.update')}
      </button>
      <RGModalMessage />
    </div>
  );
};

export default BetActions;
