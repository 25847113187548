import {
  StatementDataStatuses,
  StatementOriginNoteTypes,
  StatementOriginTypes,
  StatementTransactionTypes,
  TExchangeTypes,
  TPLExchangeTypes
} from 'constants/myBets';
import { DropdownItem, FailureActionPayload, OddsType, TPeriods } from 'types';
import { MarketType } from 'types/markets';
import { AccountStatementSortByField, BetSide, BetType, BetTypeValue, TOfferState } from 'types/myBets';

export type MyBets = {
  data: TMyBetsResponse;
  statementData: TStatementResponse;
  PLData: TPLResponse;
  isMyBetsFirstLoaded: boolean;
  isProfitLossFirstLoaded: boolean;
  isStatementFirstLoaded: boolean;
  PLTotal: number;
  loadingPLTotal: boolean;
  PLTotalEvent: number;
  loadingPLTotalEvent: boolean;
  isOnMyBetsPage: boolean;
  loading: boolean;
  cancelAllUnmatchedBets: boolean;
  cancelAllUnmatchedBetsActionId: number | null;
  showBetDetails: boolean;
  bet?: TMyBetsContentItem;
  cancelledBet?: TMyBetsContentItem;
  betUpdated: boolean;
  startDate?: Date;
  endDate?: Date;
  timeRange: { translationKey: string; name: string };
  pageSize: number;
  pageNumber: number;
  charges: string[];
  sportsList: Sport[];
  loadingSportsList: boolean;
  gamesList: Game[];
  loadingGamesList: boolean;
  multiCurrencyValue: string | null;
  loadingProducts: boolean;
  areProductsLoaded: boolean;
  accountProducts: TAccountProducts;
  isCustomPeriod: boolean;
  error: FailureActionPayload | null;
  eventTypeFilter?: DropdownItem;
  sortBy: Record<TSortByType, 'asc' | 'desc' | null>;
  isMobileFiltersOpen: boolean;
  mobileFilters: TMobileFiltersPayload;
  mobileSorting?: TMobileSorting | null;
  mobileBettingPLSorting?: TMobileSorting | null;
  accountStatementFilters: {
    transactions: StatementTransactionType[];
    sort?: AccountStatementSorting;
  };
  statementDataLoading: boolean;
  mobileStatementItemDetails: null | TStatementContentItem;
  mobileStatementSorting: MobileStatementSorting | null;
  myBetsCancelBetsState: Record<string, { isLoading: boolean; isSuccess: boolean; isError: boolean }>;
  search: string | null;
  exportNotification: TExportNotification;
  chargesLoading: boolean;
  plDataLoading: boolean;
  mobileEditOfferId?: number | null;
};

export type AccountStatementSorting = Record<AccountStatementSortByField, 'asc' | 'desc'>;
export type TAccountProducts = Record<TBetsType, TAccountProduct | null>;
export type MobileStatementSorting = { name: AccountStatementSortByField; order: 'asc' | 'desc' };

export type TAccountProduct = Record<string, boolean>;

export type Game = {
  name: string;
  channelId: number;
  gameId: number;
  translations?: Record<string, string>;
};
export type Sport = {
  id: string;
  name: string;
  translations?: Record<string, string>;
};

export type TimeRange = { translationKey: string; name: string };

export const BetsType = {
  All_MY_BETS: 'allMyBets',
  ALL_PROFIT_AND_LOSS: 'allProfitAndLoss',
  ALL_ACCOUNT_STATEMENT: 'allAccountStatement'
} as const;

export type TBetsType = typeof BetsType[keyof typeof BetsType];

export type TAccountProductsPayload = {
  currency: string;
  betsType: TBetsType;
  onFinishCallback?: () => void;
};

export type SuccessAccountProduct = Record<TBetsType, TAccountProduct>;

export type TGetBetPayload = {
  betsType?: BetType;
  betsTypes?: BetType[];
  sortBy?: string[];
  startDate: string | number | (() => number);
  endDate: string | number | (() => number);
  size: number;
  page: number;
  currency?: string;
  asianView?: boolean;
  bettingDay?: boolean;
  search?: string;
  onChangePage?: (page: number) => void;
  noResetMyBetsCancelState?: boolean;
};
export type TFetchStatementPayload = Omit<TGetBetPayload, 'betsType'> & {
  accountStatementOrigin: string[];
  onChangePage?: (page: number) => void;
};

export type IGetPLPayload = {
  startDate: string | number | (() => number);
  endDate: string | number | (() => number);
  size: number;
  page: number;
  sortBy?: string[];
  eventTypeId?: string;
  betType: BetTypeValue;
  currency?: string;
  asianView?: boolean;
  bettingDay?: boolean;
  netOfCommission?: boolean;
};

export type IGetPLTotalPayload = {
  startDate: string | number | (() => number);
  endDate: string | number | (() => number);
  betType: string;
  eventTypeId?: string;
  currency?: string;
  asianView?: boolean;
  bettingDay?: boolean;
  netOfCommission?: boolean;
};

export type ICancelBetPayload = {
  params: {
    [key: string]: {
      offerId: number;
      size: string;
      handicap: string;
      price: number;
      betType: string;
      selectionId: string;
    }[];
  };
  bet: TMyBetsContentItem;
};

export type TCancelBetResponse = { status: string; error: Error | null };

export type TMyBetsResponse = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TMyBetsContentItem[];
};

export type TStatementResponse = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TStatementContentItem[];
};

export type TPLResponse = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TPLContentItem[];
};

export type TPLTotalResponse = {
  totalProfit: number;
};

export type StatementDataStatus = typeof StatementDataStatuses[keyof typeof StatementDataStatuses];
export type StatementOriginType = typeof StatementOriginTypes[keyof typeof StatementOriginTypes];
export type StatementTransactionType = typeof StatementTransactionTypes[keyof typeof StatementTransactionTypes];
export type StatementOriginTypeNote = typeof StatementOriginNoteTypes[keyof typeof StatementOriginNoteTypes];

export type TStatementContentItem = {
  alternativeBackOdds: string | number | null;
  alternativeBackOddsRounded: string | number | null;
  asian?: boolean;
  avgPrice: number;
  avgPriceRounded: number;
  avgPriceMatched: number;
  balance: string;
  betType: 'EXCHANGE' | 'GAME';
  bettingType: string;
  commissionType: string;
  credit: string;
  currency: string;
  deadHeated?: boolean | null;
  eachWayDivisor: number | null;
  eventTypeId: string;
  debit: string;
  description: string;
  disabledLayOdds: boolean;
  depositWithdrawal: boolean;
  gameIdLabel?: string;
  inPlay?: boolean;
  liability?: number;
  numberOfWinners: number | null;
  oddsType?: OddsType;
  placedDate: number;
  score?: string;
  settledDate: number;
  side: BetSide;
  size: string;
  sportName: string;
  status: StatementDataStatus | null;
  refId: string;
  raceName: string;
  triggeredByCashOut: boolean;
  fancyMarket: boolean;
  marketStartTime: number;
  marketId: string;
  handicap: number;
  competitionName: string;
  mainEventName: string;
  eventName: string;
  marketNameWithParents: string;
  marketName: string;
  marketType: MarketType;
  selectionName: string;
  originType: StatementOriginType;
  originTypeLabel: string;
  originNoteType?: null | StatementOriginTypeNote;
  originNoteTypeLabel?: null | string;
  outright: boolean;
};

export type TPLContentItem = {
  betType: string;
  commissionSum: null;
  mainEventName: string;
  eventName: string;
  marketId: string;
  marketName: string;
  marketNameWithParents: string;
  profit: string;
  raceName: string;
  settledDate: number;
  startTime: number;
};

export type TMyBetsContentItem = {
  asian?: boolean;
  alternativeBackOdds: string | number;
  alternativeBackOddsRounded: string | number;
  averagePrice: number;
  averagePriceRounded: number;
  avgPriceMatched: number;
  betType: string;
  bettingType: string;
  cancelledByOperator: boolean;
  cancelledDate: number;
  competitionId?: number;
  competitionName?: string;
  commissionType: string;
  currency: string;
  currencyExRate: string;
  currencyRate: number;
  disabledLayOdds: boolean;
  eachWayDivisor: string;
  eventId: string;
  mainEventId: string;
  mainEventName: string;
  eventName: string;
  marketNameWithParents: string;
  eventTypeId: number;
  fancyView: boolean;
  groupName: string;
  handicap: string;
  inPlay?: boolean;
  interval: number;
  liability: string;
  numberOfWinners: number;
  marketId: string;
  marketName: string;
  marketStartDate: number;
  marketType: string;
  marketUnit: string;
  masterAccountId: number;
  masterCurrency: string;
  matchedDate: number;
  maxUnitValue: number;
  minUnitValue: number;
  oddsType: OddsType;
  offerId: number;
  offerState: TOfferState;
  outright?: boolean;
  pastTotalLiability: string;
  persistenceEnabled: boolean;
  persistenceType: string;
  placedDate: number;
  potentialProfit: string;
  price: number;
  profit: string;
  profitNet: string;
  raceName: string;
  score?: string;
  side: BetSide;
  size: string;
  sizeCancelled: string;
  sizeLapsed: string;
  sizeRemaining: string;
  sizeVoided: string;
  totalWinnings: string;
  selectionId: number;
  selectionName: string;
  settledDate: number;
  sportName: string;
  triggeredByCashOut: boolean;
};

export const SortByFields = {
  DATE: 'sort_date',
  PLACED_DATE: 'sort_placed_date',
  START_DATE: 'market_start_time',
  SETTLED_DATE: 'settled_date'
} as const;

export type TSortByType = typeof SortByFields[keyof typeof SortByFields];

export type TMobileFiltersPayload = {
  betTypes?: BetType[];
  isBetTypesChanged?: boolean;
  currency?: string;
  isCurrencyChanged?: boolean;
  product?: TExchangeTypes | TPLExchangeTypes;
  isProductChanged?: boolean;
  timeDisplayFormat?: string;
  isTimeDisplayFormatChanged?: boolean;
  startDate?: Date;
  endDate?: Date;
  period?: TPeriods;
  isDateRangeChanged?: boolean;
  statementSorting?: AccountStatementSortByField;
  isStatementSortingChanged?: boolean;
  transactionsTypes?: StatementTransactionType[];
  isTransactionsTypesChanged?: boolean;
  eventTypeId?: string;
  isEventTypeIdChanged?: boolean;
  netCommission?: boolean;
  isNetCommissionChanged?: boolean;
  sorting?: TSortByType;
  isSortingChanged?: boolean;
};

export type TMobileSorting = { type: TSortByType; order: 'asc' | 'desc' | null };

export type TExportNotification = { error?: string; isDownloading?: boolean; isDisabled?: boolean };
